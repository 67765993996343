import { Content, RichTextField } from "@prismicio/client";
import React, {
  InputHTMLAttributes,
  ReactNode,
  SelectHTMLAttributes,
} from "react";
import {
  Control,
  FieldErrors,
  FieldValues,
  Message,
  MultipleFieldErrors,
  Ref,
  SubmitHandler,
  UseFormGetValues,
  UseFormRegister,
  UseFormReset,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
  ValidationMode,
} from "react-hook-form";
import { AnyObjectSchema } from "yup";
import { ImageField } from "@prismicio/types";
import { TSpecialist } from "./pagesType";

export type FormPreProps = {
  register: UseFormRegister<FieldValues>;
  isSubmitting: boolean;
  isSubmitted?: boolean;
  errors: { [error: string]: FieldError | undefined };
  control?: Control;
  defaultValues?: FieldValues;
  reset?: UseFormReset<FieldValues>;
  watch?: UseFormWatch<FieldValues>;
  setValue?: UseFormSetValue<FieldValues>;
};
export type FormProps = {
  // Function component to display in the form
  renderForm: (formProps: FormPreProps) => React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmitForm: SubmitHandler<any>;
  schema?: AnyObjectSchema;
  defaultValues?: FieldValues;
  onWatch?: (values: FieldValues) => void;
  mode?: keyof ValidationMode;
};
export type SelectValue = {
  label: string;
  value: TSelectValue;
  toggledContent?: ReactNode;
  icon?: string;
};
export type TSelectValue = string | number | object;
export interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  type?: string;
  label?: string | React.ReactElement;
  register?: UseFormRegister<FieldValues>;
  control?: Control;
  error?: string;
  wrapperClass?: string;
  className?: string;
  values: SelectValue[] | undefined;
  startLabel?: string;
  endLabel?: string;
}
export type InputType = "text" | "tel" | "email" | "number" | "password";
export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  type?: InputType | string;
  label?: string | React.ReactElement;
  register?: UseFormRegister<FieldValues>;
  control?: Control;
  error?: string;
  wrapperClass?: string;
  className?: string;
  readonly?: boolean;
  startLabel?: string;
  endLabel?: string;
  direction?: string;
  errors?: FieldErrors;
}
export interface TextareaProps
  extends InputHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  label?: string | React.ReactElement;
  register?: UseFormRegister<FieldValues>;
  control?: Control;
  error?: string;
  wrapperClass?: string;
  className?: string;
  readonly?: boolean;
  useSpecialCharacter?: boolean;
}
export type FieldError = {
  type?: string;
  ref?: Ref;
  types?: MultipleFieldErrors;
  message?: Message;
};
export interface FieldPropsType<T, S> {
  register?: UseFormRegister<FieldValues>;
  control?: Control;
  name: string;
  type?: InputType | string;
  error?: T;
  label?: string | S;
  placeholder?: string;
  required?: boolean;
  wrapperClass?: string;
  values?: SelectValue[] | undefined;
  options?: SelectValue[];
  class?: string;
  className?: string;
  readonly?: boolean;
  marks?: Record<string | number, ReactNode>;
  min?: number;
  max?: number;
  numberOfInput?: number;
  minDate?: Date;
  useSpecialCharacter?: boolean;
  maxDate?: Date;
  defaultValue?: string | number;
  startLabel?: string;
  endLabel?: string;
  disabled?: boolean;
  setValue?: UseFormSetValue<FieldValues>;
  trigger?: UseFormTrigger<FieldValues>;
  fieldsToComplete?: IFieldsToComplete[];
  countryCode?: string;
  availableCountries?: string[];
  displayInTwoCols?: boolean;
  inlineInputs?: boolean;
  allowSingleValue?: boolean;
  help?: string;
  helpTitle?: string;
  change?: (value: string) => void;
  showCalendarIcon?: boolean;
}
export interface ScreenFieldtype {
  type: string;
  name: string;
  key: string;
}
export interface ScreenPropsType<T, S> {
  register?: UseFormRegister<FieldValues>;
  control?: Control;
  name: string;
  currentStep: number;
  watch?: UseFormWatch<FieldValues>;
  getValue?: UseFormGetValues<FieldValues>;
  type?: InputType | string;
  key?: string;
  readOnly?: boolean;
  error?: T;
  errors?: FieldErrors;
  setValue?: UseFormSetValue<FieldValues>;
  fieldValues?: {
    [x: string]: any;
  };
  label?: string | S;
  sublabel?: string;
  endLabel?: string;
  placeholder?: string;
  required?: boolean;
  wrapperClass?: string;
  options?: SelectValue[];
  className?: string;
  trustpilot?: Content.TrustpilotDocument;
  threeReasonsSlice?: Content.ThreeReasonsConsultationSlice;
  direction?: string;
  min?: number;
  max?: number;
  defaultValue?: string | number;
  suffix?: string;
  prefix?: string;
  text?: RichTextField;
  availableCountries?: string[];
  countryCode?: string;
  goTo?: (step: number) => void;
  reviews?: Content.AvisDocument[];
  isValid?: boolean;
}
export interface FieldProps {
  variant: string;
  props: FieldPropsType<string, React.ReactElement>;
}
export interface ScreenProps {
  props: ScreenPropsType<string, React.ReactElement>;
  specialist?: TSpecialist;
  image?: ImageField;
  consentBilanMinceur?: RichTextField;
  data?: Content.BilanBlocSliceDefaultPrimary;
}
export type TFunctionFieldProps = {
  field: FieldPropsType<{ [error: string]: string | number }, string>;
  register?: UseFormRegister<FieldValues>;
  errors: { [error: string]: FieldError | undefined };
  control?: Control;
};
export interface IOption extends SelectValue {
  disabled?: boolean;
}
export interface IRadioGroup extends InputProps {
  values: SelectValue[] | undefined;
  hasFullWidth?: boolean;
  displayInTwoCols?: boolean;
  inlineInputs?: boolean;
  allowSingleValue?: boolean;
  useIconAsClass?: boolean;
}
export interface ICode extends InputProps {
  numberOfInput: number;
  change?: (value: string) => void;
}

export interface ICheckboxGroup extends InputProps {
  values: SelectValue[] | undefined;
  change?: (values: string) => void;
  displayInTwoCols?: boolean;
  inlineInputs?: boolean;
  allowSingleValue?: boolean;
}
export interface RadioElementProps
  extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  name: string;
  label: string | React.ReactElement;
  value: string;
  disabled?: boolean;
  className?: string;
  icon?: string;
}

export interface ISlider extends InputProps {
  min?: number;
  marks?: Record<string | number, React.ReactNode> | undefined;
  defaultVallue?: number | string;
  startLabel?: string;
  endLabel?: string;
}

export interface IFieldsToComplete {
  name: string;
  type: string;
}

export interface IErrors {
  [error: string]: FieldError | undefined;
}

export enum EAddressFieldTypes {
  POSTCODE = "postcode",
  CITY = "city",
  STREET = "street",
}

export enum EFieldTypes {
  TEXT = "text",
  EMAIL = "email",
  TEL = "tel",
  NUMBER = "number",
  PASSWORD = "password",
  SELECT = "select",
  RADIO = "radio",
  CHECKBOX = "checkbox",
  SLIDER = "slider",
  AUTOCOMPLETE_ADDRESS = "autocompleteAddress",
  DATE = "date",
  MULTI_SELECT = "multiselect",
  TEXTAREA = "textarea",
  CODE = "code",
  NUMBER_WITH_ARROWS = "numberWithArrows",
}

export enum EScreenTypes {
  GENDER = "gender",
  NUMBER = "number",
  IMC = "imc",
  OBJECTIF_WEIGHT = "object_weight",
  RADIO = "radio",
  INFO = "info",
  YOYO_EFFECT = "yoyo_effect",
  TEXT = "text",
  DATE = "date",
  EMAIL = "email",
  CHAT = "chat",
  TEL = "tel",
  LOADING = "loading",
}
export enum EScreenKeys {
  GENDER = "Gender",
  HEIGHT = "Height",
  WEIGHT = "Weight",
  IMC = "IMC",
  DESIRED_WEIGHT = "Desired Weight",
  YOUR_GOAL = "Your Goal",
  HEALTH = "Health",
  SLEEP_PROBLEMS = "Sleep Problems",
  TIRED_MORNING = "Tired mornings",
  ACTIVITY_LEVEL = "Activity level",
  SLEEPING_INFO = "Info about sleeping",
  DIGESTIVE_PROBLEMS = "Digestive Problems",
  DIGESTIVE_PROBLEMS_INFO = "Digestive Problems Info",
  DIETS_IN_10_YEARS = "Diets in 10 years",
  YOYO_EFFECT = "Yoyo Effect",
  FIRST_NAME = "First Name",
  BIRTHDATE = "Birth date",
  EMAIL = "Email",
  CHAT = "Chat",
  PHONE_NUMBER = "Phone number",
}

export enum EStabOptions {
  BREAKFAST = "breakfast",
  LUNCH = "lunch",
  SNACK = "snack",
  DINNER = "dinner",
  DESSERT = "dessert",
}

export type DateOptions = {
  language: "fr";
  format: "dd-MM-YYYY";
};

export const generateYears = () => {
  const currentYear = new Date().getFullYear();

  return Array.from({ length: 100 }, (_, i) => currentYear - i);
};

export const generateMonths = () => Array.from({ length: 12 }, (_, i) => i + 1);

export const generateMonthsLabels = () => {
  return Array.from({ length: 12 }, (_, i) =>
    new Date(0, i).toLocaleString("fr", { month: "long" }),
  );
};

export const generateDays = (month: number, year: number) => {
  const daysInMonth = new Date(year, month, 0).getDate();

  return Array.from({ length: daysInMonth }, (_, i) => i + 1);
};
