/* eslint-disable  @typescript-eslint/no-unsafe-return */
/* eslint-disable  @typescript-eslint/no-unsafe-assignment */
import { AxiosError, AxiosResponse } from "axios";
import instanceAxios from "@middleware/helpers/api/axios.instance";
import {
  EResponseType,
  IApiType,
  pathParamsType,
  queryParamsType,
  TResponseError,
} from "@middleware/types";
import { END_POINT_API_START } from "@middleware/constants";
import { getResourceUrl } from "../global/urls";
import { serializeError } from "../global/errors";

/*
// ressourceLocator : string : endpoint of API
// pathParams : {} | url with ?xx=yy
// queryParams : {} | params to send to API
*/

export default class WrapApi {
  typeApi: IApiType;
  endPointStart: string;

  constructor(
    typeApi: IApiType = "api",
    endPointStart: string = END_POINT_API_START,
  ) {
    this.typeApi = typeApi;
    this.endPointStart = endPointStart;
  }

  getResource = async <T>(
    ressourceLocator: string,
    pathParams?: pathParamsType,
    queryParams?: queryParamsType,
    responseType = EResponseType.JSON,
  ): Promise<AxiosResponse<T> | AxiosResponse<AxiosError>> => {
    const url = getResourceUrl(
      this.typeApi,
      this.endPointStart,
      ressourceLocator,
      pathParams,
    );

    return await this.getFetchResponse(url, responseType, queryParams);
  };

  getFetchResponse = async (
    url: string,
    responseType: EResponseType,
    queryParams?: queryParamsType,
  ) => {
    const res = await instanceAxios
      .get(url, {
        params: queryParams,
        responseType,
      })
      .then((response) => {
        return response;
      })
      .catch((err: AxiosResponse<AxiosError & TResponseError>) => {
        return serializeError(err);
      });

    return res;
  };

  postResource = async <T>(
    ressourceLocator: string,
    body?: object,
    pathParams?: pathParamsType,
    queryParams?: queryParamsType,
  ): Promise<AxiosResponse<T> | AxiosResponse<AxiosError>> => {
    const url = getResourceUrl(
      this.typeApi,
      this.endPointStart,
      ressourceLocator,
      pathParams,
    );

    return await this.postFetchResponse(url, body, queryParams);
  };

  postFetchResponse = async (
    url: string,
    body?: object | string | number,
    queryParams?: queryParamsType,
  ) => {
    const parmBody = typeof body === "object" ? { ...body } : body;

    const res = await instanceAxios
      .post(
        url,
        {
          ...parmBody,
        },
        { params: queryParams },
      )
      .then((response) => {
        return response;
      })
      .catch((err: AxiosResponse<AxiosError & TResponseError>) => {
        return serializeError(err);
      });

    return res;
  };

  patchResource = async <T>(
    ressourceLocator: string,
    body?: object,
    pathParams?: pathParamsType,
    queryParams?: queryParamsType,
  ): Promise<AxiosResponse<T> | AxiosResponse<AxiosError>> => {
    const url = getResourceUrl(
      this.typeApi,
      this.endPointStart,
      ressourceLocator,
      pathParams,
    );

    return await this.patchFetchResponse(url, body, queryParams);
  };

  patchFetchResponse = async (
    url: string,
    body?: object | string | number,
    queryParams?: queryParamsType,
  ) => {
    const parmBody = typeof body === "object" ? { ...body } : body;

    const res = await instanceAxios
      .patch(
        url,
        {
          ...parmBody,
        },
        { params: queryParams },
      )
      .then((response) => {
        return response;
      })
      .catch((err: AxiosResponse<AxiosError & TResponseError>) => {
        return serializeError(err);
      });

    return res;
  };
  putResource = async <T>(
    ressourceLocator: string,
    body?: object,
    pathParams?: pathParamsType,
    queryParams?: queryParamsType,
  ): Promise<AxiosResponse<T> | AxiosResponse<AxiosError>> => {
    const url = getResourceUrl(
      this.typeApi,
      this.endPointStart,
      ressourceLocator,
      pathParams,
    );

    return await this.putFetchResponse(url, body, queryParams);
  };

  putFetchResponse = async (
    url: string,
    body?: object | string | number,
    queryParams?: queryParamsType,
  ) => {
    const parmBody = typeof body === "object" ? { ...body } : body;

    const res = await instanceAxios
      .put(
        url,
        {
          ...parmBody,
        },
        { params: queryParams },
      )
      .then((response) => {
        return response;
      })
      .catch((err: AxiosResponse<AxiosError & TResponseError>) => {
        return serializeError(err);
      });

    return res;
  };

  deleteResource = async <T>(
    ressourceLocator: string,
    pathParams?: pathParamsType,
    queryParams?: queryParamsType,
  ): Promise<AxiosResponse<T> | AxiosResponse<AxiosError>> => {
    const url = getResourceUrl(
      this.typeApi,
      this.endPointStart,
      ressourceLocator,
      pathParams,
    );
    const response = await instanceAxios.delete(url, {
      params: queryParams,
    });

    return response;
  };
}
