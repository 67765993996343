import {
  AGENCY_CLOSE_AT,
  AGENCY_OPEN_AT,
  BILAN_GENDER_MAN_VALUE,
  BILAN_GENDER_STEP,
  BILAN_GENDER_WOMAN_VALUE,
  FIRST_WEIGHT_TO_LOSE,
  HEALTY_IMC,
  IMC_BREAKPOINTS,
} from "@middleware/constants";
import {
  FieldPropsType,
  IObject,
  IProgram,
  ScreenPropsType,
  SelectValue,
  TBilanData,
  TBmi,
  TObjectiveWeightBilan,
  TResponseBmi,
  TResponseObjectiveWeightBilan,
  TResponseResultBilan,
  TResultBilan,
  TResultsBilanDataItem,
} from "@middleware/types";
import { Content } from "@prismicio/client";
import { generateSlug, getFormattedDate } from "./utils";

export const serializeResultBilanData = (obj: IObject) =>
  Object.entries(obj).reduce((acc: TResultsBilanDataItem, [key, value]) => {
    const fieldToExclude = ["email", "phone", "name", "agreementCheck"];
    if (!fieldToExclude.includes(key)) {
      acc[key] = {
        question: key,
        answer: value,
      };
    }

    return acc;
  }, {});
export const serializeBilanDataToSend = (obj: IObject) =>
  Object.entries(obj).reduce((acc: TResultsBilanDataItem, [key, value]) => {
    const fieldToExclude = [
      "email",
      "phone",
      "first_name",
      "agreementCheck",
      "chat",
      "name",
      "slot",
      "acceptance",
      "imc",
      "your_goal",
      "info_about_sleeping",
      "digestive_problems_info",
      "yoyo_effect",
      "phone_number",
      "final_step",
    ];
    const intKeys = ["weight", "height", "desired_weight"];
    const serializedFieldNames: IObject = {
      weight: "current_weight",
      health: "health_pov",
      tired_mornings: "morning_fatigue",
      digestive_problems: "digestion_problems",
      diets_in_10_years: "diets_number",
    };
    const optimizedValue = intKeys.includes(key) ? parseFloat(value) : value;
    if (Object.keys(serializedFieldNames).includes(key)) {
      acc[serializedFieldNames[key]] = {
        question: serializedFieldNames[key],
        answer: optimizedValue,
      };
    } else if (!fieldToExclude.includes(key)) {
      acc[key] = {
        question: key,
        answer: optimizedValue,
      };
    }

    return acc;
  }, {});

export const getRecommendedBilanProgram = (
  programs: IProgram[],
  recomendedProgramCode: string,
) => {
  return programs.find((program) => program.code === recomendedProgramCode);
};

export const getAge = (date: string) => {
  const birthday = new Date(date);
  const today = new Date();
  const age = today.getFullYear() - birthday.getFullYear();

  const m = today.getMonth() - birthday.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
    return age - 1;
  }

  return age;
};

export const serializeResultBilan = (
  response: TResponseResultBilan,
): TResultBilan => {
  const serializeBmi = (responseBmi: TResponseBmi): TBmi => {
    return {
      originalValue: responseBmi.original_value,
      value: responseBmi.value,
      message: responseBmi.message,
    };
  };

  return {
    qls: response.qls,
    bmi: {
      height: response.bmi.height,
      current: serializeBmi(response.bmi.current),
      desired: serializeBmi(response.bmi.desired),
      recommended: serializeBmi(response.bmi.recommended),
      healthy: serializeBmi(response.bmi.healthy),
    },
    weightGoal: {
      height: response.weightGoal.height,
      currentWeight: response.weightGoal.currentWeight,
      desired: response.weightGoal.desired,
      bmiBasedRecommendation: response.weightGoal.bmiBasedRecommendation,
    },
    customerId: response.customerId,
  };
};
export const serializeObjectiveWeightBilan = (
  response: TResponseObjectiveWeightBilan,
): TObjectiveWeightBilan => {
  return {
    bundleDuration: response.bundleRecommended.duration,
    weightGoal: response.weightGoal,
    isAchievable: response.weightLossGoal <= FIRST_WEIGHT_TO_LOSE,
  };
};

export const getFirstObjectiveParagraph = (
  bilanData: TBilanData,
  slice: Content.PageResultBilanDocument,
): string => {
  const { weightToLoss, desiredImc, currentWeight, healtyWeight } = bilanData;
  if (currentWeight === healtyWeight)
    return slice.data.fifthParagraph?.toString() ?? "";
  if (currentWeight < healtyWeight)
    return slice.data.sixthParagraph?.toString() ?? "";
  if (weightToLoss < FIRST_WEIGHT_TO_LOSE && desiredImc > HEALTY_IMC)
    return slice.data.firstParagraph?.toString() ?? "";
  if (weightToLoss > FIRST_WEIGHT_TO_LOSE && desiredImc > HEALTY_IMC)
    return slice.data.secondParagraph?.toString() ?? "";
  if (weightToLoss < FIRST_WEIGHT_TO_LOSE && desiredImc < HEALTY_IMC)
    return slice.data.thirdParagraph?.toString() ?? "";

  return slice.data.fourthParagraph?.toString() ?? "";
};

export const getSecondObjectiveParagraph = (
  bilanData: TBilanData,
  slice: Content.PageResultBilanDocument,
): string => {
  if (bilanData.currentWeight <= bilanData.healtyWeight)
    return slice.data.recommendedProgramText3?.toString() ?? "";
  if (bilanData.desiredImc > HEALTY_IMC)
    return slice.data.recommendedProgramText1?.toString() ?? "";

  return slice.data.recommendedProgramText2?.toString() ?? "";
};

export const generateBilanData = (
  resultBilanData: TResultBilan,
): TBilanData => {
  const healtyWeight = Math.round(
    HEALTY_IMC * Math.pow(resultBilanData.bmi.height / 100, 2),
  );
  const data = {
    currentWeight: resultBilanData.weightGoal.currentWeight,
    recommendedWeight:
      resultBilanData.weightGoal.bmiBasedRecommendation.weightLossGoal <= 0
        ? resultBilanData.weightGoal.desired.weightGoal
        : resultBilanData.weightGoal.bmiBasedRecommendation.weightGoal,
    healtyWeight,
    desiredWeight: resultBilanData.weightGoal.desired.weightGoal,
    height: resultBilanData.bmi.height,
    currentImc: resultBilanData.bmi.current.originalValue,
    desiredImc: resultBilanData.bmi.desired.originalValue,
    recommendedImc: resultBilanData.bmi.recommended.originalValue,
    healthyImc: resultBilanData.bmi.healthy.originalValue,
    weightToLoss: resultBilanData.weightGoal.desired.weightLossGoal,
    firstWeightToLoss:
      resultBilanData.weightGoal.bmiBasedRecommendation.weightLossGoal,
  };

  return data;
};

export const getMinField = (
  slice: Content.BilanBlocSlice,
  fieldKey: string,
): number => {
  const foundItem = slice.items.find((item) => item.step === fieldKey);

  return foundItem !== undefined
    ? parseInt(foundItem.min?.toString() ?? "0")
    : 0;
};
export const getMaxField = (
  slice: Content.BilanBlocSlice,
  fieldKey: string,
): number => {
  const foundItem = slice.items.find((item) => item.step === fieldKey);

  return foundItem !== undefined
    ? parseInt(foundItem.max?.toString() ?? "0")
    : 300;
};
export const serializeBilanScreens = (
  slice: Content.BilanBlocSlice,
): ScreenPropsType<{ [error: string]: string | number }, string>[] =>
  slice.items.map((item) => {
    const options = generateOptionsFromString(item.options?.toString() ?? "");
    if (BILAN_GENDER_STEP === generateSlug(item.step)) {
      options.map((option) => {
        if (option.value === BILAN_GENDER_WOMAN_VALUE) {
          option.icon = slice.primary.WomanImage.url ?? "";
        }
        if (option.value === BILAN_GENDER_MAN_VALUE) {
          option.icon = slice.primary.manImage.url ?? "";
        }

        return option;
      });
    }

    return {
      options,
      type: item.type?.toString(),
      name: generateSlug(item.step?.toString()),
      label: item.titre?.toString(),
      sublabel: item.subtitle?.toString(),
      placeholder: item.placeholder?.toString(),
      direction: item.direction.toString(),
      min: parseInt(item.min?.toString() ?? "0"),
      max: parseInt(item.max?.toString() ?? "100"),
      endLabel: item.suffix?.toString(),
      text: item.screenText,
      currentStep: 0,
    };
  });
export const getStepIndex = (
  steps: FieldPropsType<{ [error: string]: string | number }, string>[],
  field: string,
) => {
  return steps.findIndex((step) => step.name === field);
};
export const generateOptionsFromString = (input: string) => {
  if (input === "") return [];
  const pairs = input.split(" | ");

  return pairs.map((pair) => {
    const [iconValue, label] = pair.split(":");

    if (iconValue.includes(".")) {
      const [icon, value] = iconValue.split(".");

      return {
        icon,
        value,
        label,
      };
    }

    return {
      value: iconValue, // iconValue in this case is just the value
      label,
    };
  });
};
export const addXMonths = (numberOfMonths: number): string => {
  const today = new Date();
  today.setMonth(today.getMonth() + numberOfMonths);

  return getFormattedDate(today.toString(), {
    day: "2-digit",
    month: "long",
    year: "numeric",
  });
};
export const getMonthsLabels = (numberOfMonths: number): string[] => {
  const today = new Date();
  const monthLabels: string[] = [];

  for (let i = 0; i < numberOfMonths; i++) {
    const nextMonth = new Date(today.getFullYear(), today.getMonth() + i);
    monthLabels.push(nextMonth.toLocaleString("fr-FR", { month: "long" }));
  }

  return monthLabels;
};

export const isActiveBmiCategory = (
  value: number,
  min?: number,
  max?: number,
) => {
  if (min === undefined && max === undefined) {
    return false;
  }

  if (min === undefined && max !== undefined) {
    return value <= max;
  }

  if (max === undefined && min !== undefined) {
    return value > min;
  }

  return value > min! && value <= max!;
};

export const calculateIMCPercentage = (value: number) => {
  const breakpoints = IMC_BREAKPOINTS;
  const percentagePerSection = 100 / (breakpoints.length - 1);

  for (let i = 0; i < breakpoints.length - 1; i++) {
    const min = breakpoints[i];
    const max = breakpoints[i + 1];

    if (value >= min && value <= max) {
      const sectionPercentage =
        ((value - min) / (max - min)) * percentagePerSection;

      return i * percentagePerSection + sectionPercentage;
    }
  }

  return value >= breakpoints[breakpoints.length - 1] ? 100 : 0;
};

export const getSlotsOptions = (): SelectValue[] => {
  const options: SelectValue[] = [
    { value: "09h-11h", label: "09h-11h" },
    { value: "11h-13h", label: "11h-13h" },
    { value: "13h-14h", label: "13h-14h" },
    { value: "14h-16h", label: "14h-16h" },
    { value: "16h-18h", label: "16h-18h" },
    { value: "18h-19h", label: "18h-19h" },
  ];

  const currentHour = new Date().getHours();
  if (currentHour >= AGENCY_OPEN_AT && currentHour < AGENCY_CLOSE_AT - 1) {
    options.unshift({ value: "maintenant", label: "pages.maintenant" });
  }

  return options;
};

export const getDateTimeSlot = (slot: string, phoneNumber: string) => {
  if (phoneNumber === "") return;

  const [startHour] = slot.split("h-").map(Number);
  const now = new Date();
  const slotTime = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    startHour,
    0,
    0,
  );
  const isTomorrow = slotTime <= now;
  const selectedDate = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() + (isTomorrow ? 1 : 0),
  );
  const formattedDate = getFormattedDate(selectedDate.toISOString(), {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  const formattedTime = slotTime.toLocaleTimeString("fr-FR", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  return `${formattedDate} ${formattedTime}`;
};
export const checkTimeBetweenRangeOfHours = (range: string): boolean => {
  const now = new Date();

  const [startHour, endHour] = range
    .split("-")
    .map((time) => parseInt(time.replace("H", "")));

  const startTime = new Date(now);
  startTime.setHours(startHour, 0, 0, 0);

  const endTime = new Date(now);
  endTime.setHours(endHour, 0, 0, 0);

  return now >= startTime && now <= endTime;
};
