import Image from "next/image";
import { FC } from "react";
import LogoFooter from "@static/images/dietbon-violet.svg";
import LogoBlanc from "@static/images/dietbon-violet.svg";

export interface Props {
  type?: string;
}

export const Logo: FC<Props> = ({ type }) => {
  return (
    <>
      {type === "footer" ? (
        <Image
          alt="Dietbon"
          src={LogoFooter}
          width="200"
          height="43"
          unoptimized={true}
        />
      ) : (
        <Image
          alt="Dietbon"
          src={LogoBlanc}
          width="177"
          height="63"
          unoptimized={true}
        />
      )}
    </>
  );
};
