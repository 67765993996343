import Image from "next/image";
import cn from "clsx";
import { useAuth, useCart } from "@middleware/hooks";
import { URL_PAGE_CART } from "@middleware/constants";
import Picto from "@static/images/header/cart-shape2.svg";
import { getFormattedPrice } from "@middleware/helpers";
import { Link } from "@components/ui";
import style from "./PictoCart.module.scss";

export const PictoCart = () => {
  const { cart } = useCart();
  const { isAuthenticated } = useAuth();

  return cart && cart.total > 0 ? (
    <div className={cn(style.cart, { [style.connected]: isAuthenticated })}>
      <Link href={URL_PAGE_CART} className="simple" prefetch={false}>
        <Image src={Picto} alt="" />
        {getFormattedPrice(cart.total)}
      </Link>
    </div>
  ) : null;
};
