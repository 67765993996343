import { TResponseError } from "@middleware/types";
import { AxiosError, AxiosResponse, HttpStatusCode } from "axios";
import { i18n } from "next-i18next";

export const getErrorTransKey = (errorCode?: string) => {
  if (errorCode === undefined) return "";

  const codes: Partial<Record<string, string>> = {
    eb208fcc: "error.InvalidEmail",
    cb059443: "error.UserNotSponsor",
    d35109dd: "error.CustomerEngaged",
    c3df4ad8: "error.CustomerNotEngaged",
    a612b7bd: "error.InvalidInput",
    "4634a3e3": "error.UnauthorizedAction",
    c610d443: "error.ResourceNotFound",
    cc43fe8b: "error.InvalidShipment",
    ac87091c: "error.CompleteFormOptimum",
    fc776bff: "error.ChannelNotSupported",
    "4f22c662": "error.InternalServerError",
    "1ffe600c": "error.UnavailableShippingMethods",
  };

  return codes[errorCode] ?? "";
};

export const serializeError = (
  err: AxiosResponse<AxiosError & TResponseError>,
) => {
  const successCodes = [
    HttpStatusCode.Ok,
    HttpStatusCode.Accepted,
    HttpStatusCode.NonAuthoritativeInformation,
    HttpStatusCode.NoContent,
  ];

  if (
    typeof err.data !== "string" &&
    typeof err.data !== "undefined" &&
    !successCodes.includes(err.status)
  ) {
    const errorTitleKey = getErrorTransKey(err.data.errorCode);

    err.data.title =
      i18n?.t(errorTitleKey, {
        defaultValue: i18n.t("error.sorryMessage"),
      }) ?? "";
  }

  return err;
};
