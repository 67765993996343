export const TEXTAREA_MAX_LENGTH = 30;
export const INPUT_NUMBER_DEFAULT_MIN = 0;
export const INPUT_NUMBER_DEFAULT_MAX = 300;
export const DEFAULT_CODE_LENGTH = 5;
export const FORM_PHONE_KEY = "phone";
export const CHECKUP_NUTRITION_APPOINTMENT_STEP = "appointment";
export const NOTE_PER_TEN = Array.from({ length: 11 }).map((_, index) => ({
  label: `${index}`,
  value: `${index}`,
}));
