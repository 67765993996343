import dynamic from "next/dynamic";
import React, { FC } from "react";
import { SliderForm } from "@components/ui";
import { EFieldTypes, FieldProps } from "@middleware/types";
import { DEFAULT_CODE_LENGTH } from "@middleware/constants";

const SelectForm = dynamic(
  () =>
    import("@components/ui/Forms/Select/SelectForm").then(
      (mod) => mod.SelectForm,
    ),
  {
    ssr: false,
  },
);
const CheckboxForm = dynamic(
  () =>
    import("@components/ui/Forms/Checkbox/Checkbox").then(
      (mod) => mod.CheckboxForm,
    ),
  {
    ssr: false,
  },
);
const Input = dynamic(
  () => import("@components/ui/Forms/Input/Input").then((mod) => mod.Input),
  {
    ssr: false,
  },
);

const Textarea = dynamic(
  () =>
    import("@components/ui/Forms/Textarea/Textarea").then(
      (mod) => mod.Textarea,
    ),
  {
    ssr: false,
  },
);

const InputNumber = dynamic(
  () =>
    import("@components/ui/Forms/InputNumber/InputNumber").then(
      (mod) => mod.InputNumber,
    ),
  {
    ssr: false,
  },
);

const RadioButtons = dynamic(
  () =>
    import("@components/ui/Forms/RadioButtons/RadioButtons").then(
      (mod) => mod.RadioButtons,
    ),
  {
    ssr: false,
  },
);

const TelInput = dynamic(
  () =>
    import("@components/ui/Forms/TelInput/TelInput").then(
      (mod) => mod.TelInput,
    ),
  {
    ssr: false,
  },
);
const DatePicker = dynamic(
  () =>
    import("@components/ui/Forms/DatePicker/DatePicker").then(
      (mod) => mod.DatePickerForm,
    ),
  {
    ssr: false,
  },
);

const CheckboxGroup = dynamic(
  () =>
    import("@components/ui/Forms/CheckboxGroup/CheckboxGroup").then(
      (mod) => mod.CheckboxGroup,
    ),
  {
    ssr: false,
  },
);

const PlacesAutocomplete = dynamic(
  () =>
    import("@components/ui/Forms/PlacesAutocomplete/PlacesAutocomplete").then(
      (mod) => mod.PlacesAutocomplete,
    ),
  {
    ssr: false,
  },
);
const Code = dynamic(
  () => import("@components/ui/Forms/Code/Code").then((mod) => mod.Code),
  {
    ssr: false,
  },
);

const Field: FC<FieldProps> = ({ variant, props }) => {
  const {
    register,
    control,
    name,
    type,
    error,
    label,
    required = false,
    placeholder,
    wrapperClass,
    useSpecialCharacter,
    values,
    className,
    marks,
    min,
    max,
    defaultValue,
    startLabel,
    endLabel,
    readonly,
    setValue,
    trigger,
    disabled,
    fieldsToComplete,
    countryCode,
    availableCountries,
    minDate,
    maxDate,
    numberOfInput,
    displayInTwoCols,
    inlineInputs,
    allowSingleValue,
    change,
    showCalendarIcon,
  } = props;

  return (
    <>
      {(variant === EFieldTypes.TEXT ||
        variant === EFieldTypes.EMAIL ||
        variant === EFieldTypes.NUMBER ||
        variant === EFieldTypes.PASSWORD) && (
        <Input
          name={name}
          type={type}
          label={label}
          register={register}
          error={error}
          wrapperClass={wrapperClass}
          placeholder={placeholder}
          className={className}
          readOnly={readonly}
          required={required}
          startLabel={startLabel}
          endLabel={endLabel}
        />
      )}
      {variant === EFieldTypes.SELECT && (
        <SelectForm
          name={name}
          label={label}
          control={control}
          error={error}
          wrapperClass={wrapperClass}
          placeholder={placeholder}
          values={values}
          disabled={disabled}
          className={className}
          required={required}
          startLabel={startLabel}
          endLabel={endLabel}
        />
      )}
      {variant === EFieldTypes.CHECKBOX && (
        <CheckboxForm
          name={name}
          control={control}
          label={label}
          error={error}
          wrapperClass={wrapperClass}
          className={className}
          required={required}
        />
      )}
      {variant === EFieldTypes.RADIO && (
        <RadioButtons
          name={name}
          control={control}
          label={label}
          error={error}
          wrapperClass={wrapperClass}
          className={className}
          values={values}
          defaultValue={defaultValue}
          required={required}
        />
      )}
      {variant === EFieldTypes.SLIDER && (
        <SliderForm
          name={name}
          control={control}
          label={label}
          error={error}
          wrapperClass={wrapperClass}
          className={className}
          marks={marks}
          min={min}
          defaultValue={defaultValue}
          startLabel={startLabel}
          endLabel={endLabel}
        />
      )}
      {variant === EFieldTypes.AUTOCOMPLETE_ADDRESS && (
        <PlacesAutocomplete
          name={name}
          type={type}
          label={label}
          error={error}
          wrapperClass={wrapperClass}
          placeholder={placeholder}
          className={className}
          readOnly={readonly}
          setValue={setValue}
          trigger={trigger}
          fieldsToComplete={fieldsToComplete}
          required={required}
          control={control}
          selectedCountry={countryCode}
        />
      )}
      {variant === EFieldTypes.TEL && (
        <TelInput
          name={name}
          label={label}
          error={error}
          wrapperClass={wrapperClass}
          className={className}
          countryCode={countryCode}
          availableCountries={availableCountries}
          required={required}
          control={control}
          placeholder={placeholder}
          change={change}
        />
      )}
      {variant === EFieldTypes.DATE && (
        <DatePicker
          name={name}
          error={error}
          label={label}
          required={required}
          wrapperClass={wrapperClass}
          className={className}
          control={control}
          placeholder={placeholder}
          maxDate={maxDate}
          minDate={minDate}
          showCalendarIcon={showCalendarIcon}
        />
      )}
      {variant === EFieldTypes.MULTI_SELECT && (
        <CheckboxGroup
          name={name}
          label={label}
          error={error}
          wrapperClass={wrapperClass}
          className={className}
          required={required}
          control={control}
          values={values}
          defaultValue={defaultValue}
          displayInTwoCols={displayInTwoCols}
          inlineInputs={inlineInputs}
          allowSingleValue={allowSingleValue}
        />
      )}
      {variant === EFieldTypes.NUMBER_WITH_ARROWS && (
        <InputNumber
          name={name}
          type={type}
          label={label}
          register={register}
          error={error}
          wrapperClass={wrapperClass}
          placeholder={placeholder}
          className={className}
          readOnly={readonly}
          required={required}
          max={max}
        />
      )}
      {variant === EFieldTypes.TEXTAREA && (
        <Textarea
          name={name}
          type={type}
          label={label}
          register={register}
          error={error}
          wrapperClass={wrapperClass}
          placeholder={placeholder}
          className={className}
          readOnly={readonly}
          required={required}
          useSpecialCharacter={useSpecialCharacter}
        />
      )}
      {variant === EFieldTypes.CODE && (
        <Code
          name={name}
          control={control}
          error={error}
          label={label}
          wrapperClass={wrapperClass}
          numberOfInput={numberOfInput ?? DEFAULT_CODE_LENGTH}
          defaultValue={defaultValue}
        />
      )}
    </>
  );
};

export default Field;
