export const URL_GOOGLE_PLAY =
  "https://play.google.com/store/apps/details?id=com.dietbon";
export const URL_APP_STOR =
  "https://apps.apple.com/fr/app/dietbon-by-aliive/id1542187266";
export const URL_HOME = "/";
export const URL_HOME_B = "/home-b";
export const URL_HOME_C = "/home-c";
export const URL_PAGE_ALC = "/a-la-carte";
export const URL_PAGE_METHODE = "/methode";
export const URL_PAGE_DURATION = "/programme-minceur";
export const URL_PAGE_DURATION_2 = "/programme-minceur-2";
export const URL_PAGE_DURATION_3 = "/programme-minceur-3";
export const URL_PAGE_STABILISATION = "/programme-stabilisation";
export const URL_FLEX_SINGLE_MEALS = "/programme-minceur-flexibilite/";
export const URL_PAGE_MEALS = "/programme-minceur-flexibilite/[code]";
export const URL_STAB_SINGLE_MEALS = "/stabilisation-meals/";
export const URL_PAGE_STAB_MEALS = "/stabilisation-meals/[code]";
export const URL_PAGE_REGIME = "/regime-box";
export const URL_PAGE_WOMEN = "/programme-minceur-femme";
export const URL_PAGE_MEN = "/programme-minceur-homme";
export const URL_PAGE_OPTIMUM = "/repas-adaptes-microbiote";
export const URL_PAGE_BILAN = "/bilan-minceur-personnalise";
export const URL_PAGE_AVIS = "/temoignages";
export const URL_PAGE_FAQ = "/faq";
export const URL_PAGE_CONTACT = "/contacts";
export const URL_PAGE_CGV = "/cgv";
export const URL_PAGE_CGU = "/cgu";
export const URL_PAGE_MENTIONS = "/mentions-legales";
export const URL_PAGE_CART = "/checkout/cart";
export const URL_PAGE_SUCCESS = "/checkout/success";
export const URL_PAGE_SUCCESS_ALC = "/checkout/success-alc";
export const URL_PAGE_SUCCESS_OPTIMUM = "/checkout/success-optimum";
export const URL_PAGE_SUCCESS_ORIGINAL = "/checkout/success-original";
export const URL_PAGE_SUCCESS_STAB = "/checkout/success-stabilisation";
export const URL_PAGE_AGENDA = "/checkout/success-original/agenda";
export const URL_PAGE_SPONSOR = "/checkout/success-original/sponsor";
export const URL_PAGE_CLUB = "/checkout/success-original/club";
export const URL_PAGE_APPLICATION = "/checkout/success-original/application";
export const URL_PAGE_QUIZ = "/checkout/success-optimum/quiz";
export const URL_PAGE_RECOMMENDATION =
  "/checkout/success-optimum/recommendations";
export const URL_PAGE_ONBOARDING = "/checkout/success-original/onboarding";
export const URL_MEDIA = "/media/image/";
export const URL_PARRAINAGE = "/parrainage";
export const URL_PAGE_CUSTOMER = "/account";
export const URL_PAGE_CUSTOMER_REGISTER = URL_PAGE_CUSTOMER + "/register";
export const URL_PAGE_CUSTOMER_ORDERS = URL_PAGE_CUSTOMER + "/shippings";
export const URL_PAGE_CUSTOMER_ORDERS_COMING =
  URL_PAGE_CUSTOMER + "/shippings/upcoming";
export const URL_PAGE_CUSTOMER_ORDERS_PAST =
  URL_PAGE_CUSTOMER + "/shippings/past";
export const URL_PAGE_TRACKING = URL_PAGE_CUSTOMER + "/follow-up";
export const URL_PAGE_CUSTOMER_WEIGHT = URL_PAGE_CUSTOMER + "/follow-up/weight";
export const URL_PAGE_CUSTOMER_DIETITIAN =
  URL_PAGE_CUSTOMER + "/follow-up/dietitian";
export const URL_PAGE_CUSTOMER_CLUB =
  URL_PAGE_CUSTOMER + "/follow-up/club-dietbon";
export const URL_PAGE_CUSTOMER_DIET_CHAT =
  URL_PAGE_CUSTOMER + "/follow-up/diet-chat";
export const URL_PAGE_PROGRAM = URL_PAGE_CUSTOMER + "/program";
export const URL_PAGE_CUSTOMER_CURRENT_PROGRAM =
  URL_PAGE_CUSTOMER + "/program/current-program";
export const URL_PAGE_CUSTOMER_CURRENT_PROGRAM_DETAIL =
  URL_PAGE_CUSTOMER + "/program/current-program/details";
export const URL_PAGE_CUSTOMER_INFOS =
  URL_PAGE_CUSTOMER + "/program/my-information";
export const URL_PAGE_CUSTOMER_GUIDE = URL_PAGE_CUSTOMER + "/program/my-guide";
export const URL_PAGE_SPONSOR_SUBMENU_MOBILE = URL_PAGE_CUSTOMER + "/sponsor";
export const URL_PAGE_CUSTOMER_CREDIT_BALANCE =
  URL_PAGE_CUSTOMER + "/sponsor/credit-balance";
export const URL_PAGE_CUSTOMER_EARN_CREDIT =
  URL_PAGE_CUSTOMER + "/sponsor/earn-credit";
export const URL_PAGE_CUSTOMER_SUBSCRIPTION =
  URL_PAGE_CUSTOMER + "/subscription";
export const URL_PAGE_CUSTOMER_UPDATE_PASSWORD =
  URL_PAGE_CUSTOMER + "/update-password";
export const URL_PAGE_SHIPMENT_DETAILS =
  URL_PAGE_CUSTOMER + "/shippings/details/";
export const URL_PAGE_SHIPMENT_EDIT = URL_PAGE_CUSTOMER + "/shippings/edit/";
export const URL_PAGE_EDIT_PROGRAM =
  URL_PAGE_CUSTOMER + "/program/edit-program/";
export const URL_PAGE_RESTART_PROGRAM =
  URL_PAGE_CUSTOMER + "/program/restart-program/";
export const URL_PAGE_EDIT_MEALS = URL_PAGE_SHIPMENT_EDIT + "/select-meal/";
export const URL_PAGE_EDIT_OPTIMUM_MEALS =
  URL_PAGE_SHIPMENT_EDIT + "optimum-meals/";
export const URL_PAGE_EDIT_ADDRESS = URL_PAGE_SHIPMENT_EDIT + "address/";
export const URL_PAGE_EDIT_PAYMENT = URL_PAGE_SHIPMENT_EDIT + "payment/";
export const URL_PAGE_IMPERSONATE = "/impersonate";
