import { TOOLTIP_COLOR, WHITE_COLOR } from "@middleware/constants";
import cn from "clsx";
import { ETooltipPosition, TTooltipPosition } from "@middleware/types";
import { FC, ReactNode } from "react";
import style from "./Tooltip.module.scss";

interface TooltipProps {
  children: ReactNode;
  tooltipData: ReactNode;
  background?: string;
  textColor?: string;
  position?: TTooltipPosition;
  className?: string;
  alwaysVisible?: boolean;
}

export const Tooltip: FC<TooltipProps> = ({
  children,
  tooltipData,
  background = TOOLTIP_COLOR,
  textColor = WHITE_COLOR,
  position = ETooltipPosition.POSITION_TOP,
  className,
  alwaysVisible = false,
}) => {
  return (
    <div
      className={cn(style.wrapper, className, {
        [style.alwaysVisible]: alwaysVisible,
      })}
    >
      <div
        className={cn(style.tooltip, style[position])}
        style={{ color: background }}
      >
        <span style={{ color: textColor }}>{tooltipData}</span>
      </div>
      {children}
    </div>
  );
};
