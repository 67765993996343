export const VAR_WEIGHT_TO_LOSE = "{{weightToLose}}";
export const VAR_PROGRAM_DURATION = "{{NumberOfMonths}}";
export const VAR_FIRST_WEIGHT_TO_LOSE = "{{firstWeightToLose}}";
export const VAR_MONTH = "{{month}}";
export const VAR_SCORE_LEVEL = "{{scoreLevel}}";
export const VAR_IMC_LEVEL = "{{imcLevel}}";
export const VAR_CURRENT_WEIGHT = "{{currentWeight}}";
export const VAR_OBJECTIVE_WEIGHT = "{{objectiveWeight}}";
export const VAR_CURRENT_IMC_LABEL = "{{currentImcLabel}}";
export const VAR_OBJECTIVE_IMC_LABEL = "{{objectiveImcLabel}}";
